<template>
  <dialogComp :title="titleD" :isShow="isShow" @close="close" @sure="sure">
    <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
      <el-form-item label="角色类型:" prop="type">
         <el-radio-group v-model="formData.type">
          <el-radio :label="1">系统角色</el-radio>
          <el-radio :label="2">商家角色</el-radio>
      </el-radio-group>
      </el-form-item>
      <inputFormComp
        label="角色名称:"
        prop="name"
        :hval="formData.name"
        @blur="nameBlur"
        placeholder="请输入角色名称"
      />
      <inputFormComp
        inputType="number"
        label="排序:"
        prop="orderNum"
        :hval="formData.orderNum"
        @blur="orderNumBlur"
        placeholder="请输入排序"
      />
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import { rolesAdd, rolesEdit } from '@/api'
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data () {
    return {
      formData: {
        type: 1,
        name: '',
        orderNum: '',
        remark: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: '请输入序号', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    titleD () {
      return this.title === 'add' ? '新增角色' : '编辑角色'
    }
  },
  watch: {
    isShow (val) {
      if (val && this.title === 'edit') {
        const { name, remark, id, type, orderNum } = this.info
        this.formData.name = name
        this.formData.remark = remark
        this.formData.type = type
        this.formData.id = id
        this.formData.orderNum = orderNum
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    nameBlur (val) {
      this.formData.name = val
    },
    orderNumBlur (val) {
      this.formData.orderNum = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.formData = {
        type: 1,
        name: '',
        orderNum: '',
        remark: ''
      }
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { name, remark, id, type, orderNum } = this.formData
          if (this.title === 'add') {
            rolesAdd({ name, remark, type, orderNum }).then(res => {
              if (res.code === '1') {
                this.$message.success('新增成功')
                this.close()
              }
            })
          } else {
            rolesEdit({ name, remark, id, type, orderNum }).then(res => {
              if (res.code === '1') {
                this.$message.success('编辑成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>

</style>
