<template>
  <div class="layout_common roles_manage">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              :hval="name"
              @blur="nameBlur"
              style="margin-right: 20px"
              isShowIcon
              placeholder="角色名查询"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add('add')" v-permission="'新增'">新 增</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="orderNum" label="序号">
            <template v-slot="scope">
              {{scope.row.orderNum}}
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            width="180"
            fixed="right"
          >
            <template v-slot="scope">
              <btnComp
                btnType="text"
                v-if="scope.row.status === 1"
                @click="send(scope.row)"
                v-permission="'分配权限'"
                >分配权限</btnComp
              >
              <btnComp
                btnType="text"
                @click="add('edit', scope.row)"
                v-if="scope.row.status === 1"
                v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp
                btnType="text"
                :style="{ color: scope.row.status === 1 ? 'red' : '#385FFF' }"
                @click="updateStatus(scope.row)"
                v-permission="'禁用'"
                >{{ scope.row.status === 1 ? "禁用" : "恢复" }}</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
        <add :isShow="addShow" @close="close" :title="title" :info="info" />
        <send :isShow="sendShow" @close="close" :info="info" />
        <dialogComp
          title="提示"
          :isShow="hintShow"
          @close="close"
          @sure="sureUpdateStatus"
        >
          <p>确定{{ info.status === 1 ? "禁用" : "恢复" }}吗？</p>
        </dialogComp>
      </div>
    </div>
  </div>
</template>
<script>
import add from './add'
import send from './send'
import { rolesList, rolesUpdateStatus } from '@/api'
export default {
  name: '',
  props: {},
  components: { add, send },
  data () {
    return {
      addShow: false,
      hintShow: false,
      sendShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      title: '',
      info: {},
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { slot: 'orderNum' },
        { prop: 'name', label: '角色名称' },
        { prop: 'typeName', label: '角色类型' },
        { prop: 'remark', label: '备注' },
        { prop: 'statusName', label: '状态' },
        { prop: 'updatedTime', label: '更新时间' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        name: this.name
      }
      rolesList(params).then((res) => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    nameBlur (val) {
      this.name = val
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.name = ''
      this.getList()
    },
    add (val, info) {
      this.title = val
      if (val === 'edit') {
        this.info = info
      }
      this.addShow = true
    },
    // 恢复禁用
    updateStatus (info) {
      this.hintShow = true
      this.info = info
    },
    sureUpdateStatus () {
      const { id, status } = this.info
      rolesUpdateStatus({ id, status: status === 1 ? 2 : 1 }).then((res) => {
        if (res.code === '1') {
          this.$message.success('状态修改成功')
          this.close()
        }
      })
    },
    // 分配权限
    send (info) {
      this.info = info
      this.sendShow = true
    },
    close () {
      this.addShow = false
      this.hintShow = false
      this.sendShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
